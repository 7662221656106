/* eslint-disable no-bitwise, consistent-return */
import React, { useState, useEffect, Fragment } from 'react';
import { isEmpty, get, isNumber } from 'lodash';
import Social from '../social/social';
import PropTypes from 'prop-types';
import axios from 'axios';

export const StickyBar = ({
  slug,
  post,
  overrideShareIcon,
  overrideSocialText,
  activeTab,
  setActiveTab,
  location,
  scroll,
  externalData,
  hideTopBorder,
  position,
  svgWidth,
  svgHeight
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [lists, setLists] = useState([]);
  const [landerUrl, setLanderUrl] = useState(null);
  const [isMobile, setIsMobile] = useState(null);

  let displayingOnPost = false;
  if ('type' in post && post?.type === 'post') {
    displayingOnPost = true;
  }

  const checkIfMobile = x => {
    setIsMobile(x.matches);
  };

  // Detect screen size on render, and watch for changes to it.
  useEffect(() => {
    const x = window.matchMedia('(max-width: 640px)');
    checkIfMobile(x);

    x.addEventListener('change', checkIfMobile);
    return () => x.removeEventListener('change', checkIfMobile);
  }, []);

  useEffect(
    () => {
      if (isMobile && setActiveTab) setActiveTab(0);
    },
    [isMobile]
  );

  const determineBrightness = backgroundColor => {
    const color = backgroundColor.substring(1); // strip #
    const rgb = parseInt(color, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    if (luma < 128) {
      return '#FFFFFF';
    }
    return '#000000';
  };

  const handleCategoryChange = idx => {
    if (location === 'lander') {
      setActiveTab(idx);
      scroll();
    } else {
      window.location.href = landerUrl ? `${landerUrl}?view=${idx}` : '#';
    }
  };

  // const handlePopup = e => {
  //   if (/^http/gi.test(e.currentTarget.value)) {
  //     window.location.href = e.currentTarget.value;
  //   }
  // };

  // const handleNavListClick = (arr, position) => {
  //   const clickEvent = new EventModel(
  //     `BWI Bottom Sticky Nav- ${get(arr, 'category', 'no category')}`,
  //     'bottomNavClick',
  //     'click',
  //     `nav-${get(arr, 'category', 'no category')}`,
  //     `${position + 1} | ${arr.length}`
  //   );

  //   clickEvent.triggerTrackingEvent(
  //     'default-click',
  //     clickEvent.getTrackingObject()
  //   );
  // };

  const getColor = idx => {
    /* eslint-disable */
    switch (slug) {
      case 'bwi-2022':
        if (activeTab === idx) {
          return '#fff';
        }

      case 'brands-that-matter-2022':
        if (activeTab === idx) {
          return '#fff';
        }

      case 'mic-2023':
        if (activeTab === idx) {
          return '#000';
        }

      case 'mic-2024':
        if (activeTab === idx) {
          return { background: 'linear-gradient(to left, #518DFF, #F36435)' };
        }
        return { background: '#000' };

      default:
        if (location === 'article' || activeTab === idx) {
          return data.text_color;
        }
        return '#999';
    }
    /* eslint-enable */
  };
  // eslint-disable-next-line
  const getBackGroundColor = idx => {
    switch (slug) {
      case 'btm-2023':
        if (activeTab !== idx) {
          return { background: '#000' };
        }
        break;

      case 'mic-2024':
        if (activeTab === idx || displayingOnPost) {
          return { background: 'linear-gradient(to left, #518DFF, #F36435)' };
        }
        return { background: '#000' };

      case 'ibd-2023':
        if (activeTab !== idx) {
          return { background: '#000' };
        }
        break;

      case 'bwi-2022':
        if (activeTab === idx) {
          return { background: '#ec008c' };
        }
        break;

      case 'bwi-2023':
        if (activeTab === idx) {
          return { background: '#ec008c' };
        }
        break;

      case 'brands-that-matter-2022':
        if (activeTab === idx) {
          return { background: '#000' };
        }
        break;

      case 'next-big-things-in-tech':
        if (activeTab !== idx) {
          return { background: '#fff' };
        }
        break;

      case 'next-big-things-in-tech-2023':
        if (activeTab !== idx) {
          return { background: '#000' };
        }

        if (activeTab === idx && idx === 0) {
          return {
            background: 'linear-gradient(90deg, #FBF04E 0%, #5779B6 100%)'
          };
        }

        if (activeTab === idx && idx === 1) {
          return {
            background: 'linear-gradient(90deg, #5777B5 0%, #DE6538 100%)'
          };
        }
        break;

      default:
    }
  };

  const handleCustomBars = () => {
    if (slug === 'mic-2024' && displayingOnPost) {
      return (
        <a
          href={data.link_url ? `${data.link_url}` : `/${data.slug}`}
          style={{
            color: '#000',
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '100%'
          }}
        >
          <div
            className="bottomStickyNav-content_box"
            style={{
              borderLeftColor: data.border_color,
              color: '#000',
              ...getBackGroundColor(slug)
            }}
            key={`box-${slug}`}
          >
            {data.display_name}
          </div>
        </a>
      );
    }
    return null;
  };

  const buildContent = type => {
    const customBar = handleCustomBars();
    if (customBar) return customBar;

    switch (type) {
      case 'title':
        return (
          <a
            href={data.link_url ? `${data.link_url}` : `/${data.slug}`}
            target="_blank"
            style={{ color: data.text_color }}
            onClick={e => {
              if (location === 'lander') {
                e.preventDefault();
                scroll();
              }
            }}
          >
            {data.display_name}
          </a>
        );

      case 'categories':
        return (
          <Fragment>
            {lists.map((list, i) => (
              <div
                className="bottomStickyNav-content_box"
                style={{
                  borderLeftColor: data.border_color,
                  color:
                    location === 'article' || activeTab === i
                      ? data.text_color
                      : '#777',
                  ...getBackGroundColor(i)
                }}
                key={`box-${i}`}
                onClick={() => handleCategoryChange(i)}
              >
                <a
                  style={{
                    color: getColor(i)
                  }}
                >
                  {list.category}
                </a>

                {data.display_category_index === i && (
                  <div className="hiddenDropdown">
                    {/* <select onChange={e => handlePopup(e)}>
                      {list.data.map((category, j) => (
                        <option
                          value={category.url}
                          onClick={() => handleNavListClick(list, j)}
                        >
                          {category.title}
                        </option>
                      ))}
                    </select> */}
                    <button>▲</button>
                  </div>
                )}
              </div>
            ))}
          </Fragment>
        );

      default:
        return null;
    }
  };

  const defaultShareIcon = () => (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width={svgWidth || '34.66'}
      height={svgHeight || '23.4'}
      viewBox="0 0 34.66 23.4"
    >
      <path
        style={{ fill: determineBrightness(data.background_color) }}
        d="M30.553-8.851a23.706,23.706,0,0,0-7.446,3.16A27.918,27.918,0,0,0,19-2.432V-15.269a27.93,27.93,0,0,0,4.108,3.258,23.711,23.711,0,0,0,7.446,3.16"
        transform="translate(4.106 20.712)"
      />
      <path
        style={{ fill: determineBrightness(data.background_color) }}
        d="M4-8.3H26.082v-1.964H4Z"
        transform="translate(0.875 21.177)"
      />
      <path
        style={{ fill: determineBrightness(data.background_color) }}
        d="M316.027,407.7H296.973V384.3h19.055v7.275h-2V386.3H298.973v19.4h15.055V400.35h2Z"
        transform="translate(-296.973 -384.3)"
      />
    </svg>
  );

  useEffect(() => {
    if (isEmpty(data)) {
      axios(`https://fc-api.fastcompany.com/api/v1/navs/tag/${slug}`)
        .then(res => {
          setData(res.data);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(
            `Error trying to load data for bottom navigation: ${error}`
          );
        });
    }
  }, []);

  useEffect(
    () => {
      if (data.display_type === 'categories') {
        const categoryFetches = [
          axios.get(
            `https://fc-api.fastcompany.com/api/v1/franchise/${slug}?token=33FDDA6B-93D3-40A7-83E9-E2AA671FEC07`
          )
        ];

        if (slug === 'ibd-2022-2') {
          categoryFetches.push(
            axios.get(`https://tempo.inc.com/ibd-2022/final`)
          );
        }

        if (slug === 'ibd-2023') {
          categoryFetches.push(
            axios.get(`https://tempo.inc.com/ibd-2023/final`)
          );
        }

        Promise.all(categoryFetches)
          .then(res => {
            const franchiseData = get(res, '[0].data'); // eslint-disable-line
            const externalListData = get(res, '[1].data'); // eslint-disable-line
            const publicUrl = get(franchiseData, 'acf.publicUrl', '#');
            // eslint-disable-next-line
            const franchise = () => {
              if (/^bwi/gi.test(slug)) return 'best_workplaces_for_innovators';
              if (/^ibd-2022-2/gi.test(slug)) return 'innovation_by_design';
              if (/^ibd-2023/gi.test(slug)) return 'innovation_by_design_2023';
              if (/^ibd/gi.test(slug)) return 'innovation_by_design';
            };

            const freshExternalData = [
              {
                category: 'Honorees',
                data: []
              },
              {
                category: 'Winners',
                data: []
              }
            ];

            const fromExternal =
              externalData.length > 0 ? externalData : freshExternalData;

            if (fromExternal.length > 0) {
              setLists(fromExternal);
            } else {
              setLists(res[0].data.acf[franchise(slug)].lists);
            }

            if (location === 'article') {
              if (publicUrl !== '' && publicUrl !== '#') {
                setLanderUrl(publicUrl);
              } else {
                const url = {
                  slug: franchise(slug).replace(/_/gi, '-'),
                  year: get(slug.match(/\d{4}$/gi), '[0]', '')
                };
                setLanderUrl(`/${url.slug}/${url.year}`);
              }
            }
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error(
              `Error trying to load list data for bottom navigation: ${error}`
            );
          });
      }
    },
    [data]
  );

  if (
    !slug ||
    isEmpty(data) ||
    data.error ||
    (data.display_type === 'categories' && isEmpty(lists))
  ) {
    return false;
  }

  return (
    <div
      className={`bottomStickyNav ${
        hideTopBorder ? 'bottomStickyNav--hideTopBorder' : ''
      }`}
      style={{
        backgroundColor: data.background_color,
        borderColor: data.border_color,
        position: isMobile ? 'fixed' : position
      }}
    >
      <div className="bottomStickyNav-content">
        {buildContent(data.display_type)}
      </div>

      <div
        className="bottomStickyNav-social"
        style={{ borderColor: data.border_color }}
        onClick={() => setOpen(!open)}
      >
        {overrideShareIcon || defaultShareIcon()}
        {open && (
          <div className="bottomStickyNav__icons">
            <Social
              modifier="StickyBar"
              disableStyle={true}
              hideRecirc={true}
              text={
                overrideSocialText ||
                get(post, 'customFields.facebook_social_copy', post.title)
              }
              uri={
                get(data, 'link_url', null) && !isNumber(get(post, 'id'))
                  ? `https://www.fastcompany.com${get(data, 'link_url')}`
                  : `https://www.fastcompany.com/${get(post, 'id')}/${get(
                      post,
                      'slug'
                    )}`
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

StickyBar.propTypes = {
  slug: PropTypes.string.isRequired,
  post: PropTypes.shape({}).isRequired,
  activeTab: PropTypes.number,
  overrideShareIcon: PropTypes.node,
  overrideSocialText: PropTypes.string,
  setActiveTab: PropTypes.func,
  location: PropTypes.string.isRequired,
  scroll: PropTypes.func,
  externalData: PropTypes.shape([]),
  hideTopBorder: PropTypes.bool,
  position: PropTypes.string,
  svgHeight: PropTypes.number,
  svgWidth: PropTypes.number
};

StickyBar.defaultProps = {
  activeTab: null,
  setActiveTab: null,
  scroll: null,
  externalData: [],
  hideTopBorder: false,
  overrideSocialText: '',
  svgHeight: null,
  svgWidth: null,
  position: '',
  overrideShareIcon: null
};
